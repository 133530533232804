import { Link } from 'gatsby'
import { Seo, ContactForm } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const ContactDeliver = () => (
  <>
    <Seo title="ご注文時の配送に関するお問い合わせ" pageUrl="contact-deliver" />
    <div className="pageComponent">
      <article
        className="contact"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">CONTACT</h1>
          <p>ご注文時の配送に関するお問い合わせ</p>
        </header>
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>CONTACT</li>
          </ul>
        </nav>
        <div className="contentSubComponent">
          <ContactForm formId="3a764daa-8f20-4e2e-903f-7e84c3b5ffc7" />
        </div>
      </article>
    </div>
  </>
)

export default ContactDeliver
